export interface SafetyTip {
    id: number;
    title: string;
    text: string;
}

export const SUBSCRIPTION_TIPS: SafetyTip[] = [
    {
        id: 340360,
        title:
            "What’s the difference between Free to Play and upgrading to a membership?",
        text: `While playing in the Free to Play it will become clear that
          there are certain in-game tools and content which is not
          available to a Free to Play player.<br /><br />When a Free to
          Play player upgrades to a membership, all of these in-game
          tools and content will then become available to them, ensuring
          that they experience the full game; all the exciting worlds
          including multiple property areas, all the battles and
          enemies, plus access to our regular in-game updates, community
          competitions and other in-game events.`,
    },
    {
        id: 340359,
        title: "How do I upgrade to a membership?",
        text: `Upgrading to a membership is easy and we have 2 options for
          you; in-game or via LEGOuniverse.com.<br /><br />In-game you
          can click on any “Become a Member” button, which will lead you
          through our secure payment process in-game.
          <br /><br />Alternatively you can go to “My Account” on
          LEGOuniverse.com and then click “Buy Membership”, and pay via
          our secure payment process online.<br /><br />In addition, as
          we appreciate that everyone is different; we have two payment
          options for your convenience - via a credit card or by
          purchasing a LEGO Universe Game Time Card from your local
          retailer. <br /><br />Note: if a player is under 13 years,
          they will need an activated LEGO ID before being able to
          upgrade. If not already done so, your LEGO ID can be activated
          via the parental email sent to the parents’ email address used
          for the original sign up.`,
    },
    {
        id: 340361,
        title: "What do I get by upgrading to a Membership?",
        text: `A monthly membership with LEGO Universe is all inclusive,
          meaning that there are no additional costs that occur during a
          membership period.<br /><br />By upgrading to a membership,
          you can access the full LEGO Universe experience, including
          all the exciting worlds, all the battles and enemies, plus
          access to our regular in-game updates, as well as all the
          content that is not able to be accessed as a Free to Play
          player e.g. chat, trade, pets etc.`,
    },
    {
        id: 340362,
        title:
            "If I decide not to continue my membership can I go back to Free to Play?",
        text: `You can always go back to Free to Play however you will not be
          able take your existing character and its associate
          achievements and skills with you. To return to the Free to
          Play you will need to set up a new account by creating a new
          LEGO ID and then build a new character.<br /><br />Remember,
          if at any point, you want to continue playing using a
          previously upgraded account, all you need to do is re-new your
          membership on that account.`,
    },
    {
        id: 227547,
        title: "How is safety supported by the monthly fee?",
        text: `Since a big part of LEGO Universe is kids hanging out with
          friends, making new ones and becoming Best Friends, everything
          will be monitored 24/7 by a team of professional moderators.
          And to optimize the quality, we have incorporated the concept
          of safety into the story of the game. In-game, our moderators
          can show up as Mythrans, a mysterious race of Minifigures with
          a history of protecting and doing good deeds for others. They
          constantly keep a watchful eye on players when they
          communicate together via the chat system, or when they build
          and bring their creations to life on their properties.
          <br /><br />In addition, chat is regulated through the use of
          white and black lists, which define what you can and cannot
          say when chatting with other players. LEGO Universe also uses
          a proprietary mechanism to do analysis of conversations over
          time. The result of this analysis is a score which is
          monitored. Those players whose scores exceed a specified
          threshold can be warned, muted, or even banned from the
          game.<br /><br />Finally, LEGO Universe has an easy-to-use
          report system that supports any safety issues that need
          investigation!`,
    },
    {
        id: 227548,
        title: "What kind of support does LEGO Universe commit to?",
        text: `Everyone behind LEGO Universe wishes to establish and maintain a
          community where kids always have fun and want to hang out, as well
          as a community where both parents and kids feel safe 24/7.<br /><br />Our
          developers are one hundred percent dedicated to challenging
          players’ creativity and engaging them with a gripping storyline
          and thrilling gameplay. Our moderators are equally committed to
          keeping a sharp eye on everything that takes place in LEGO
          Universe, so everyone can have a fun and carefree time while
          playing! <br /><br />In addition, we have a very dedicated tech
          team who make sure the game runs smoothly on all our servers
          worldwide. In our customer service department qualified assistance
          is always guaranteed, and we will help answer and solve any
          customer-related issues you may have.`,
    },
];

export const SAFETY_TIPS = [
    {
        id: 195680,
        title: "We hear you!",
        text: `We understand your concerns about safety! You may have heard
          negative information about online games. Stories about game
          addiction, online predators and sharing of private information
          are out there, and we have heard them too.<br /><br />At the
          LEGO Group, we are dedicated to the safety and well-being of
          kids above all else, and we took a long, hard look at the
          safety issues involved in online games. We spent years
          evaluating safety processes and technology, and are confident
          that we can maximize player safety in LEGO Universe. If we
          could not deliver on safety, we would not release an MMOG; it
          is as simple as that. <br /><br />The information available
          here will help inform you about safety in LEGO Universe, and
          what you can do to make the game as safe as possible for your
          children. This is a new way to play and explore creativity,
          and we look forward to sharing the many benefits with you.`,
    },

    {
        id: 173096,
        title: "What kind of game is LEGO Universe?",
        text: `LEGO Universe is a whole new quality play experience and
          creativity platform for kids to explore with their friends and
          family. Going beyond the typical, and often passive, gaming
          experience in which players consume content created by others,
          LEGO Universe gives kids the opportunity to create and share
          their own worlds and stories.<br /><br />We give players the
          tools to build anything they can dream up, encourage safe
          collaboration with others, and provide inspiring game
          environments.`,
    },

    {
        id: 173478,
        title: "Does LEGO Universe follow safety guidelines from regulatory organizations?",
        text: `Absolutely! LEGO Universe is in full compliance with CARU and
          COPPA, which both represent legal rules and guidelines that
          help protect children and their privacy when they go online.`,
    },

    {
        id: 173477,
        title: "What are CARU & COPPA?",
        text: `CARU is the Children's Advertising Review Unit, where all
          child-targeted advertising is reviewed and evaluated. The main
          goals are to avoid any misleading and incorrect advertising,
          and to make sure advertisers deal responsibly and sensitively
          with their young audience. For more detailed information see
          <a href="http://www.caru.org/" class="xLink" rel="#XLinkText"
            >www.caru.org</a
          ><br />
          <br />COPPA is the Children's Online Privacy Protection Act,
          and applies to commercial websites and online services
          directed to children, as well as websites that collect
          personal information from children. COPPA outlines the
          responsibilities any online host has to protect children's
          safety and privacy online, including when and how to get valid
          consent from a parent or guardian. For more information see
          <a href="http://www.coppa.org/" class="xLink" rel="#XLinkText"
            >www.coppa.org</a
          >`,
    },

    {
        id: 173479,
        title: "How does the LEGO Group ensure that my child’s personal data is protected?",
        text: `If a child is under the age of 13, we require parental consent
          before any personal identifiable information is collected. In
          addition, the LEGO Group strives to educate players via game
          tips and a code of conduct on the importance of never
          disclosing personal information. Talk with your child about
          the importance of maintaining privacy. It is a lesson that
          will benefit them no matter where they go online.`,
    },

    {
        id: 173480,
        title: "What measures does the LEGO Group take to ensure children are safe?",
        text: `To promote a creative online climate while safeguarding our
          most vulnerable audience, the LEGO Group has developed
          internal LEGO Universe Safety Guidelines. These protective
          practices govern all of our online activities, and include the
          following measures:<br /><br />1.
          <b>Constant moderation:</b> Our moderation team is moderating
          and monitoring LEGO Universe all the time. That’s twenty-four
          hours, seven days a week!<br /><br />2.
          <b>Very accessible safety team:</b> LEGO Universe also has a
          very clear and straight-forward reporting functionality so
          that you or your child can report to our safety team
          immediately should you have any concerns.<br /><br />3.
          <b>Code of conduct:</b> LEGO Universe has a code of conduct
          which is communicated clearly and in an age-appropriate
          manner.<br /><br />4. <b>Safety tips:</b> In addition to these
          game rules, we also provide a clear and age-appropriate set of
          safety tips to further protect and empower children.<br /><br />5.
          <b>The legend of the Mythrans:</b> The concept of safety is
          even built into the game’s story. There are in-game characters
          called the Mythrans, who have a history of protecting and
          doing good deeds for others. The Mythrans make it fun for
          players to learn about moderation and safety!<br /><br />6.
          <b>The balance of privacy and freedom of expression:</b>
          Because of the way that registration and the game play works,
          children’s privacy will be protected even as they are given
          freedom of expression and the means to be creative.`,
    },

    {
        id: 173481,
        title: "How does the LEGO Group protect children from inappropriate content ? ",
        text: `The LEGO Group uses both advanced technology and a staff of
          professional moderators to protect children during game play.
          Our technology includes a combination of blacklist
          (unacceptable) and whitelist (acceptable) filtering systems to
          automatically block inappropriate content. We also employ
          various cutting-edge software solutions that detect
          inappropriate content and behavior and alert our moderation
          team. Finally, we have real people with moderation expertise
          looking over all user submissions before they get published.`,
    },
    {
        id: 173482,
        title: "How are moderators trained?",
        text: `Since 2002, the LEGO Group has trained and employed an
          internal moderation team. This staff is steeped in LEGO
          values, has long-term experience in moderation, and receives
          continuous training in the latest safety technologies and
          trends. This team also collaborates with the children’s rights
          organization Save the Children Denmark.`,
    },

    {
        id: 173483,
        title: "Will my child be exposed to profane language?",
        text: `No. Profanity will be automatically filtered out of chat.`,
    },
    {
        id: 338939,
        title: "What chat system does the LEGO Group offer; what are the benefits and risks?",
        text: `All paying members are provided with chat based on a
          dictionary with a standard vocabulary, and you can rest
          assured that the LEGO Group employs state-of-the-art
          technology to both block and filter inappropriate chat. Add
          human moderation on top of that, and we feel that we are
          offering a service over and above industry best practice. As a
          parent, you can also take an active role by getting involved,
          and guiding your kids through online play activities (see
          below as well as the Safety Tips section for advice on how to
          educate kids on online safety).<br /><br />The chat system is
          not enabled in the Free to Play Zone.`,
    },
    {
        id: 177276,
        title: "How can I get involved to ensure my child is safe?",
        text: `<b>Play together:</b> When possible, take the time to play
        LEGO Universe with your child. As you play the game together,
        you will be spending quality time with your child, and sharing
        something that means a lot to him or her.<br /><br />
        <b>Socialize together:</b>
        Get to know your child’s online and offline friends. It will
        make it much easier for your child to approach you with any
        questions if you are already familiar with the cast of
        characters in his or her game life.`,
    },
    {
        id: 340356,
        title: "Can my parents limit how I play in LEGO Universe?",
        text: `LEGO recognizes the primary importance of parents in creating
          fun and comfortable play experiences for their children—both
          in the physical and virtual world. <br /><br />LEGO is
          committed to making all of LEGO Universe a safe online
          playground for children, and parents will also be able to
          limit some aspects of their children’s gameplay once they
          upgrade to a membership. For example, parents can decide how
          long their children are allowed to play in LEGO Universe by
          setting their Playtime Schedule.<br /><br />The playtime
          Schedule is not available to Free to Play Players.`,
    },

    {
        id: 177277,
        title: "What if my child is bullied?",
        text: `If your child tells you that he or she is being bullied,
          discuss it and find out all the details about the situation.
          Then please send a report to our moderation team, and we will
          open an investigation immediately. Bullying behavior is
          unacceptable in LEGO Universe, and we will take firm action.
          Rest assured that even if a child does not want to tell a
          parent about being bullied for fear of not being allowed to
          play the game, he or she can still very easily contact our
          safety team. We will make sure the child gets help. LEGO
          Universe also has sophisticated software solutions in place
          that detect bullying behavior automatically, and we will take
          appropriate moderation action if such behavior is detected.`,
    },
    {
        id: 177278,
        title: "Who do I contact if I have safety concerns ? ",
        text: `Should you have any concerns about safety in LEGO Universe,
          please do not hesitate to send a report to our moderation
          team, and we will open an investigation immediately.`,
    },
];

export const CODE_OF_CONDUCT: SafetyTip[] = [
    {
        id: 335940,
        title: "Don’t give out any personal information",
        text: `Your real name, home address, pictures of you, email address
          and telephone numbers are private, do not share them! The same
          applies to any social networking site IDs or any other online
          ID that might identify you in real life. In addition, please
          never attempt to arrange to meet up in real life with anyone
          you meet on the internet! Breaking any of these rules can be
          extremely dangerous!`,
    },
    {
        id: 335941,
        title: "LEGO Universe is a children’s game",
        text: `Although LEGO Universe appeals to all ages, it was made for
          children and so your submissions and behaviour should reflect
          that. This means no content or discussions that aren’t
          appropriate for 8 to 12 year olds, including tobacco, alcohol,
          intimate relationships, gambling, strong violence, nudity,
          etc. <br /><br />It also means no flirting or dating in the
          game. This includes discussions about becoming boyfriend and
          girlfriend, declarations of love and romantic advances to
          others.`,
    },
    {
        id: 335942,
        title: "Treat others with respect",
        text: `When interacting with other players, please keep in mind how
          you’d like to be treated yourself. Name-calling, bullying,
          threats, rude remarks, racism, homophobia, religious
          intolerance and any other form of disrespect toward other
          players will not be tolerated. We don’t expect everyone to be
          best friends, but we do expect all players to respect each
          other.`,
    },
    {
        id: 335943,
        title: "Bad language",
        text: `No curse words, please! This includes asterisked versions or
          adapted variations of bad language that could be offensive to
          others.`,
    },
    {
        id: 335944,
        title: "Cheaters never win",
        text: `We know it’s tempting to find a way to get more coins and
          items, but it is not fair to other LEGO Universe players if
          you do so by cheating. We don’t allow hacking, trade scamming,
          glitch exploits, kill stealing, modifying game files, sharing
          tips on how to cheat or in any other way making illegitimate
          gains or tricking other players out of their hard-earned coins
          and items.`,
    },
    {
        id: 335945,
        title: "Use your own creativity and imagination",
        text: `Intellectual property is a fancy term that basically means you
          might have copied something that doesn’t belong to you or The
          LEGO Group. Because LEGO Universe is all about imagination, we
          encourage you to use yours! Please don’t make copies of
          content that may be someone else’s. This means anything that
          belongs to well known brands, including characters from
          movies, television, video games and comics, but also creations
          originally made by other players. Basically, anything that
          didn’t come from your own creative spark!`,
    },
    {
        id: 335946,
        title: "No impersonation, please",
        text: `LEGO Universe players have great imagination, creating all
          manner of cool and exciting characters and stories. Please
          create your own too, because we don’t allow any impersonation
          of Mythrans, Brick Buddies or any other LEGO official. Please
          also steer clear of pretending to be celebrities or other
          players.`,
    },
    {
        id: 335947,
        title: "No mentions of other sites",
        text: `Because The LEGO Group cannot control the content placed on
          websites not owned by us, we do not currently allow users to
          link to or mention them. This especially means media targeted
          at an older audience, such as social networking sites and
          adult games.`,
    },
    {
        id: 335948,
        title: "Stand up for your rights",
        text: `It is not okay if someone else is offending you or others! If
          you are upset with another player, step away from your
          computer and take a deep breath before you do or say something
          you may regret. You can choose to block other players to avoid
          future conflicts or let the Mythrans know if someone is not
          following these rules.`,
    },
    {
        id: 335949,
        title: "And, finally…",
        text: `No spam! This means repeating the same thing over, and over,
          and over, and over, and over….you get the point! Doing this
          makes it difficult for others to enjoy chatting with each
          other, so please keep spam out of LEGO Universe. Enjoy the
          game!`,
    },
]