<template>
  <div class="t-list" id="SafetyTipsList">
    <div class="t-row" :id="tip.id" v-for="tip in tips" :key="tip.id">
      <b><i></i></b><i><b></b></i><em></em>
      <div class="t-cell cell-listitem">
        <ul class="parents-list">
          <li class="parents-image">
            <img src="@/assets/parents/questions.png" />
          </li>
          <li class="parents-title">{{ tip.title }}</li>
          <li class="parents-text" v-html="tip.text"></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: ["tips"],
  setup() {},
});
</script>
