<template>
  <div>
    <div class="column-row column-row-3">
      <div class="column column-large first last">
        <div class="column-top"><h1>Message other Parents</h1></div>
        <div class="column-content" id="safetyFaqMsg">
          <p>
            <!-- UniverseDictionary/images/1033/pic7931DEA06536255A7F9BCA08B91DCAF2.png -->
            <img src="@/assets/dictionary/exclaim-image.png" />
            What’s on your mind? The
            <a
              href="http://us.messageboards.lego.com/en-us/showforum.aspx?forumid=1511"
              >Parents’ Message Board</a
            >
            is a great way to share and discuss your thoughts about LEGO
            Universe with other parents.
            <a
              href="http://us.messageboards.lego.com/en-us/showforum.aspx?forumid=1511"
              class="linkArrow"
            ></a>
          </p>
        </div>
      </div>
    </div>
    <text-module>
      <div class="negative-row">
        <safety-tips-list :tips="tips"></safety-tips-list>
      </div>
    </text-module>

    <!-- FIXME: add height: automatically -->
    <div
      class="column-row column-row-3"
      style="height: 124px; margin-bottom: 80px"
    >
      <div class="column column-medium first">
        <div class="column-top"><h1>Player Safety</h1></div>
        <div class="column-content">
          <!-- UniverseDictionary/images/1033/pic6E2DCF0827615733ECBD2868B0DCF080.png -->
          <img
            class="parent-ma-image"
            src="@/assets/dictionary/parent-ma-image.png"
          />
          <ul class="parentsTouts right">
            <li>
              LEGO® Universe is all about a unique and uniquely
              <router-link to="player-safety">safe</router-link> game
              experience.
            </li>
          </ul>
          <div class="clear"></div>
        </div>
      </div>
      <div class="column column-small last">
        <div class="column-top"></div>
        <div class="column-content">
          <div class="negative-row">
            <div class="twolines-bottomtext">
              <h1>Parents' Messageboard</h1>
              <!-- /UniverseDictionary/images/1033/pic7931DEA06536255A7F9BCA08B91DCAF2.png -->
              <img
                class="exclaim-image"
                src="@/assets/dictionary/exclaim-image.png"
              />
            </div>
            <p>
              The messageboard is a great way to share thoughts and interact
              with other parents and the LEGO Universe team as well.
            </p>
            <ul class="parentsTouts">
              <a
                href="http://us.messageboards.lego.com/en-us/showforum.aspx?forumid=1511"
                class="linkArrow"
                ><span>Go to Parents' Message Board</span></a
              >
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { SAFETY_TIPS } from "@/data/safety-tips";
import SafetyTipsList from "@/components/modules/SafetyTipsList.vue";
import { defineComponent } from "vue";

export default defineComponent({
  components: {
    SafetyTipsList,
  },
  setup() {
    return { tips: SAFETY_TIPS };
  },
});
</script>
