
import { SAFETY_TIPS } from "@/data/safety-tips";
import SafetyTipsList from "@/components/modules/SafetyTipsList.vue";
import { defineComponent } from "vue";

export default defineComponent({
  components: {
    SafetyTipsList,
  },
  setup() {
    return { tips: SAFETY_TIPS };
  },
});
